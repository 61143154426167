import React, {useEffect} from 'react'

const Team = () => {

useEffect(()=>{
document.title = 'Our Team | REJO ENGINEERING (U) LIMITED';

},[]);

  return (
    <>
    <section className='section-cover team-cover' >
         <div className='content-container table-container'>
           <h2 className="home-h2 team-h2">Our Team</h2>
          <p>Directors & Administrative Staff</p>

  <table className='team-table'>
     
    <tr>
      <th>No.</th>
      <th>Name</th>
      <th>Academic
Qualifications
</th>
      <th>Position</th>
    </tr>

     <tr>
      <td>1.</td>
      <td>Okello Remis </td>
      <td>Bachelor Degree in Quantity
Surveying and a PGD in Project
Management</td>
      <td>Managing
Director/Projects
Manager</td>
    </tr>

    <tr>
      <td>2.</td>
      <td>Angee Juliet </td>
      <td>Bachelor Degree in Quantitative
Economics, CPA</td>
      <td>Director/Finance
Manager</td>
    </tr>


    <tr>
      <td>3.</td>
      <td>Ochom John</td>
      <td>Bachelor Degree in Building
Economics</td>
      <td>Operations Manager</td>
    </tr>

    <tr>
      <td>4.</td>
      <td>Opedun Isaac </td>
      <td>Bachelor of Science in Civil and
Building Engineering</td>
      <td>Company
Secretary/Head
Engineering
Department
</td>
    </tr>

     <tr>
      <td>5.</td>
      <td>Aramo Catherine</td>
      <td>Bachelor of Business
Administration
</td>
      <td>Human Resource
Manager
</td>
    </tr>

  </table>

  <p className='table-headers'>Technical Staff</p>

  <table className='team-table'>
     
    <tr>
      <th>No.</th>
      <th>Personnel</th>
      <th>Academic
Qualifications
</th>
      <th>No. of Personnel</th>
      
    </tr>

     <tr>
      <td>1.</td>
      <td>Project Managers </td>
      <td>Bachelor of Science in Civil
and Building Engineering</td>
      <td>2</td>
    </tr>

    <tr>
      <td>2.</td>
      <td>Site Engineers </td>
      <td>Bachelor of Science in Civil
and Building Engineering</td>
      <td>4</td>
    </tr>

     <tr>
      <td>3.</td>
      <td>Site foremen </td>
      <td>Diploma in Civil Engineering,
Electrical Engineering,
Mechanical/Plumbing
</td>
      <td>8</td>
    </tr>

    <tr>
      <td>4.</td>
      <td>Quantity Surveyors</td>
      <td>Bachelor Degree in Quantity
Surveying
</td>
      <td>2</td>
    </tr>

      <tr>
      <td>5.</td>
      <td>Surveyors</td>
      <td>Bachelor Degree in Land
Surveying
</td>
      <td>1</td>
    </tr>

    <tr>
      <td>6.</td>
      <td>Cleaning
Supervisors</td>
      <td>Diploma/UACE Certificate
</td>
      <td>2</td>
    </tr>

     <tr>
      <td>7.</td>
      <td>TradesMen</td>
      <td>Diploma/Craft Certificate
</td>
      <td>10</td>
    </tr>

       <tr>
      <td>8.</td>
      <td>Cleaners</td>
      <td>No specific qualification/English
language 
</td>
      <td>20</td>
    </tr>

     <tr>
      <td>9.</td>
      <td>Gardeners</td>
      <td>No specific qualification/English
language 
</td>
      <td>20</td>
    </tr>


  </table>


         </div>
    </section>
     <section className='section-cover gallery-cover' >
      <p className='chart-p'>Organisational Chart</p>
       {/* <h2 className="home-h2 gallery-h2 gallery-h2a">Organisational Chart</h2> */}
      <div className='content-container gallery gallery1 content-container2'>
 <article className='gallery-content gallery-content1'>
          <img src='./images/OC.jpg' className='gallery-image' alt='image'></img>
         </article>
      </div>

    </section>
    </>
  )
}

export default Team