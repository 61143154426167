import Carousel from 'react-bootstrap/Carousel';

function WaterSupply() {
  return (
    <Carousel  indicators={false} className="goals-carousel concrete-carousel" >
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/47.jpeg"
          alt="Third slide"
        />
        
      </Carousel.Item>

       

    </Carousel>
  );
}

export default WaterSupply;