import Carousel from 'react-bootstrap/Carousel';

function MaintCarousel() {
  return (
    <Carousel indicators={false} className='services-slides projects-slides'>
      
     
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/21a.jpeg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/23.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/25.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/30.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/47.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/48.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/49.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/50.jpeg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/51.jpeg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/52.jpeg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/53.jpeg"
          alt="Third slide"
        />
      </Carousel.Item>
 <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/54.jpeg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/55.jpeg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/56.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/57.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/58.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/59.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
    
  </Carousel>
  );
}

export default MaintCarousel;