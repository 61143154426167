import Carousel from 'react-bootstrap/Carousel';

function Residential1() {
  return (
    <Carousel indicators={false} className='goals-carousel concrete-carousel'>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/73.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/74.jpg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/75.jpg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/76.jpg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/77.jpg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/78.jpg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/79.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/80.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>  
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/81.jpg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/82.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/83.jpg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/84.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>  
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/85.jpg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/86.jpg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/87.jpg"
          alt="Second slide"
        />   
      </Carousel.Item> 
    </Carousel>
  );
}

export default Residential1;