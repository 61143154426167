import Carousel from 'react-bootstrap/Carousel';

function Land1() {
  return (
    <Carousel indicators={false} className='goals-carousel concrete-carousel'>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/143.jpg"
          alt="Second slide"
        />   
      </Carousel.Item>
     
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/144.jpg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/145.jpg"
          alt="Second slide"
        />   
      </Carousel.Item> 
      
    </Carousel>
  );
}

export default Land1;