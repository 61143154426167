import Carousel from 'react-bootstrap/Carousel';

function TelecomCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/12.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/13.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/14.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/15.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>    
    </Carousel>
  );
}

export default TelecomCarousel;