import React, {useEffect} from 'react';
import WareHouse from '../components/WareHouse';
import CommercialBlock from '../components/CommercialBlock';
import Healthcenter from '../components/Healthcenter';
import TH from '../components/TH';
import ClassroomBlocks from '../components/ClassRoomBlocks';
import WaterSupply from '../components/WaterSupply';
import Hardware from '../components/Hardware';
import Residential1 from '../components/Residential1';
import Residential2 from '../components/Residential2';
import Residential3 from '../components/Residential3';
import Residential4 from '../components/Residential4';
import Residential5 from '../components/Residential5';
import Roads1 from '../components/Roads1';
import Land1 from '../components/Land1';
import Land2 from '../components/Land2';
import Farming from '../components/Farming';
import Bridge1 from '../components/Bridge1';
import Aluminium1 from '../components/Aluminium1';
import Commercial1 from '../components/Commercial1';
import Roads2 from '../components/Roads2';
import Excavation from '../components/Excavation';
import Warehouse1 from '../components/Warehouse1';
import Aswa1 from '../components/Aswa1';

const Gallery = () => {
  useEffect(()=>{
document.title = 'Gallery | Clayeford Construction Services Ltd';

},[]);
  return (
    <section className='section-cover gallery-cover' >
       <h2 className="home-h2 gallery-h2">Gallery</h2>
      <div className='content-container gallery gallery1 gallery2'>
        <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <Residential5></Residential5>
           <p>Construction of a residential house at Kasenge, Wakiso District </p>
         </article>
         <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <Aswa1></Aswa1>
           <p>Farming at Aswa Agricultural Farms by Clayeford Construction</p>
         </article>
        <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <Residential4></Residential4>
           <p>Construction of a Residential Building</p>
         </article>

         <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <Warehouse1></Warehouse1>
           <p>Warehouse construction on Sentema road</p>
         </article>

        <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <Excavation></Excavation>
           <p>Deep excavation for residential construction in Kololo</p>
         </article>

          <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <Roads2></Roads2>
           <p>Tarmac road construction in Luzira</p>
         </article>

         <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <Commercial1></Commercial1>
           <p>Construction of a commercial building in Makindye, Kampala City</p>
         </article>


        <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <Aluminium1></Aluminium1>
           <p>Our Aluminium Products</p>
         </article>

          <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <Bridge1></Bridge1>
           <p>Construction of a bridge in Kitgum District</p>
         </article>


            <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <Hardware></Hardware>
           <p>Quality Tiles sold at Clayeford Hardware</p>
         </article>

         <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <Residential1/>
           <p>Construction of a Residential Building</p>
         </article>

         <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <Roads1/>
           <p>Rehabilitation of Roads</p>
         </article>

          <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <Land1/>
           <p>Land Opening & Seedbed Preparation in Aswa</p>
         </article>

          <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <Residential2/>
           <p>Construction of a Residential Building</p>
         </article>

         <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <Residential3/>
           <p>Construction of a Residential Building</p>
         </article>

         <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <Farming/>
           <p>Farming in Pader District - Uganda</p>
         </article>

         <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <Land2/>
           <p>Land Preparation in Gulu District - Uganda</p>
         </article>



            <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <WareHouse></WareHouse>
           <p>Paving Works for Counsel Chris Bakiza and Mama Robbin in Kilinyabigo Mutundwe</p>
         </article>

         <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <CommercialBlock></CommercialBlock>
           <p>Pavering Works in Nalumunye - Residential House</p>
         </article>

         <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <Healthcenter></Healthcenter>
           <p>Rehabilitation of Kyahi Kyabagamba Road</p>
         </article>

          <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <TH></TH>
           <p>Plumbing Works - Renovation of Bushenyi District Administration Block Phase Two</p>
         </article>

         <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <ClassroomBlocks></ClassroomBlocks>
           <p>Supplies</p>
         </article>


          <article className='gallery-content'>
          {/* <img src='./images/37.jpg' className='gallery-image' alt='image'></img> */}
           <WaterSupply></WaterSupply>
           <p>Excavation Works on Kobold Site</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/53.jpeg' className='gallery-image' alt='image'></img>
           <p>Wall Fence Construction in Lubowa</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/54.jpeg' className='gallery-image' alt='image'></img>
           <p>Lubowa Site Foundation for Retaining Wall</p>
         </article>
        
         
      </div>

    </section>
  )
}

export default Gallery