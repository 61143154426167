import React from 'react';
import {Link} from 'react-router-dom'
import {FaChevronRight, FaFacebookSquare,FaYoutube, FaTwitterSquare, FaLinkedin} from 'react-icons/fa';
import {TfiYoutube} from 'react-icons/ti'

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}



const Footer = () => {

  return (

      
        <div className='footer-bottom'>
            <p>    &copy; {new Date().getFullYear()} <span className="date"></span> Africa Travel Guides Co. Ltd | Website Developed By <a href="https://bensitesug.com" target="_blank" className="footer-link-a" rel="noopener noreferrer">BenSites</a></p>
          </div>

  )
}

export default Footer